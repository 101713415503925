import React from "react";
import AdminLayout from "../../../components/admin/AdminLayout";

export default function AdminComments() {
  return (
    <AdminLayout>
      <div>AdminComments</div>
    </AdminLayout>
  );
}
