import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

export default function Carousel() {
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getBanner = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/layout/get/layout/Banner`
      );
      setBanners(data?.layoutData?.banner);
      console.log("Banner:", banners);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 8000);

    return () => clearInterval(interval);
  }, [banners.length]);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + banners.length) % banners.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  return (
    <div className="w-[100vw] h-[45vh] sm:h-[86vh] relative overflow-hidden">
      {banners.map((banner, index) => (
        <div
          key={banner._id}
          className={`absolute inset-0 w-[100%] h-[100%] object-cover transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            src={banner.image ? banner.image : "/data/home1.jpg"}
            alt="banner"
            className="w-[100%] h-[100%] relative object-fill sm:object-fill"
          />
        </div>
      ))}
      <button
        onClick={handlePrevClick}
        className="absolute top-1/2 left-1 transform -translate-y-1/2 p-1 bg-gray-800 bg-opacity-50 cursor-pointer text-white rounded-full"
      >
        <FaAngleLeft className="h-5 w-5 text-white" />
      </button>
      <button
        onClick={handleNextClick}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 p-1 bg-gray-800 bg-opacity-50 cursor-pointer text-white rounded-full"
      >
        <FaAngleRight className="h-5 w-5 text-white" />
      </button>
    </div>
  );
}
